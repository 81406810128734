<template>
  <v-card>
    <v-app-bar flat>
      <v-toolbar-title class="text-h6 pl-0"> Customer &amp; Vendors </v-toolbar-title>

      <v-spacer></v-spacer>
      <v-menu bottom left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item v-if="customerVendorsList && customerVendorsList.length" title="Export">
            <!-- <v-list-item-title>
              <download-excel v-if="customerVendorsList && customerVendorsList.length" :key="ExcelDownload.key"
                class="btn btn-primary" :data="customerVendorsList" :before-generate="startDownload"
                :before-finish="finishDownload" :fields="ExcelDownload.fields" :name="`tenweeks_customerVendors_export.xls`">
                <v-icon>{{ icons.mdiFileExportOutline }} </v-icon>
                Download Excel
              </download-excel>
            </v-list-item-title> -->
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-data-table :headers="headers" :items="customerVendorsList" item-key="full_name" :loading="loading"
      :loading-text="datatableOptions.loadingText" :class="datatableOptions.class"
      :mobile-breakpoint="datatableOptions.mobileBreakpoint" :dense="datatableOptions.dense">
      <template #[`item.customerName`]="{ item }">
        <span class="d-block">{{item.customerName}}</span>
        <span class="d-block">{{item.customerEmail}}</span>
      </template>
      <template #[`item.vendorName`]="{ item }">
        <span class="d-block">{{item.vendorName}}</span>
        <span class="d-block">{{item.vendorEmail}}</span>
      </template>
      <template #[`item.status`]="{ item }">
        <v-chip small :color="
          item.status == 'Active'
            ? 'success'
            : item.status == 'Pending Mandate'
              ? 'warning'
              : 'primary'
        ">
          {{ item.status }}
        </v-chip>
      </template>
     <!--  <template #[`item.action`]="{ item }">
        <v-btn-toggle>
          <v-btn color="primary" x-small outlined title="Register as a Vendor"
            v-on:click="registerVendorConfirmation(item.id, item.companyName)">Register as a Vendor
          </v-btn> 
        </v-btn-toggle>
      </template>-->
    </v-data-table>
  </v-card>
</template>

<script>
import {
  mdiSquareEditOutline,
  mdiDotsVertical,
  mdiCircleEditOutline,
  mdiDeleteCircleOutline,
  mdiSend,
  mdiInformationOutline,
  mdiFileExportOutline
} from '@mdi/js'
import Util from '@/utils/axios-util.js'
import { ref, reactive } from '@vue/composition-api'

export default {
  setup() {
    const loading = ref(false)
    const saveResistrationBtnLoading = ref(false)
    const deleteObj = reactive({})
    const customerVendorsList = reactive([])
    const deleteDialog = ref(false)
    const deleteLoading = ref(false)
    const ExcelDownload = reactive({
      data: [],
      loading: false,
      columnNames: {},
      key: 'asdkpaodks',
      fields: {
        Company: 'companyName',
        'Contact Person': 'name',
        Email: 'email',
        'Contact No': 'mobileNo',
        // Action: {
        // 	field: 'type',
        // 	callback: value => {
        // 		return this.$options.filters.AuditType(value)
        // 	},
        // },
        Status: 'Status',
      },
    })

    return {
      headers: [
        { text: 'Customer Name', value: 'customerName' },
        { text: 'Customer Contact', value: 'customerMobileNo' },
        { text: 'Vendor Name', value: 'vendorName' },
        { text: 'Vendor Contact', value: 'vendorMobileNo' },
        { text: 'Status', value: 'status' },
        // { text: '', value: 'action' },
      ],
      customerVendorsList,
      loading,
      ExcelDownload,
      // icons
      icons: {
        mdiDotsVertical,
        mdiSquareEditOutline,
        mdiDotsVertical,
        mdiCircleEditOutline,
        mdiDeleteCircleOutline,
        mdiSend,
        mdiInformationOutline,
        mdiFileExportOutline,
      },
    }
  },
  data() {
    return {
      mandateDetailsDialog: false,
      mandateDetailsDialogkey: 'asdaosdjo',
      mandateDetails: {},
    }
  },
  mounted() {
    let e = this
    e.getData()
  },
  methods: {
    getData: function () {
      let e = this
      e.loading = true
      Util.Axios.get('/network/customer/get-paged-customer-vendors')
        .then(function (response) {
          e.customerVendorsList = response.data.data.items
        })
        .catch(function (response) { })
        .finally(function (response) {
          e.loading = false
        })
    },
    deleteCustomerConfirmation: function (id) {
      let e = this
      if (id) {
        e.deleteObj = e.customerVendorsList.filter(p => p.id == id)[0]
        e.deleteDialog = true
      } else {
        e.errorMessage('Invalid customer selected to delete')
      }
    },
    deleteCustomer: function () {
      let e = this
      if (e.deleteObj.id) {
        e.deleteLoading = true
        Util.Axios.delete(`/customer/${e.deleteObj.id}/delete`)
          .then(function (response) {
            if (response.data.succeeded) {
              e.successMessage('Customer Deleted successfully.')
              e.getData()
              e.deleteDialog = false
            } else {
              e.errorMessage(e.generateErrorMessage(response.data.errors))
            }
          })
          .catch(function (response) { })
          .finally(function (response) {
            e.deleteLoading = false
          })
      } else {
        e.errorMessage('Invalid customer selected.')
      }
    },
    startDownload() {
      let e = this
      e.ExcelDownload.loading = true
    },
    finishDownload() {
      let e = this
      e.ExcelDownload.loading = false
    },
    registerVendorConfirmation(id,company) { 
      let e = this;
      e.registerVendorData={
        id,
        company
      }
      e.showRegisterVendorConfirmation = true;

    },
    registerAsVendor(){
      let e = this;
       e.loading = true

       if(!e.registerVendorData.id){
        e.errorMessage('Invalid customer selected.')
        return false;
       }
      Util.Axios.post(`/customer/register-as-vendor`, {
        tenantcustomerVendors:[
          {customerId:parseInt(e.registerVendorData.id)}
        ]
      })
        .then(function (response) {
          if (response.data.succeeded) {
            e.successMessage(`Company is registered as Vendor successfully.`)
            e.getData()
          } else {
            e.errorMessage(e.generateErrorMessage(response.errors))
          }
        })
        .catch(function (response) { })
        .finally(function (response) {
          e.registerVendorData={};
            e.RegisterVendorConfirmation = false
          e.loading = false
        })
    }
  },
}
</script>
