var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-app-bar',{attrs:{"flat":""}},[_c('v-toolbar-title',{staticClass:"text-h6 pl-0"},[_vm._v(" Customer & Vendors ")]),_c('v-spacer'),_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiDotsVertical))])],1)]}}])},[_c('v-list',[(_vm.customerVendorsList && _vm.customerVendorsList.length)?_c('v-list-item',{attrs:{"title":"Export"}}):_vm._e()],1)],1)],1),_c('v-data-table',{class:_vm.datatableOptions.class,attrs:{"headers":_vm.headers,"items":_vm.customerVendorsList,"item-key":"full_name","loading":_vm.loading,"loading-text":_vm.datatableOptions.loadingText,"mobile-breakpoint":_vm.datatableOptions.mobileBreakpoint,"dense":_vm.datatableOptions.dense},scopedSlots:_vm._u([{key:"item.customerName",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"d-block"},[_vm._v(_vm._s(item.customerName))]),_c('span',{staticClass:"d-block"},[_vm._v(_vm._s(item.customerEmail))])]}},{key:"item.vendorName",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"d-block"},[_vm._v(_vm._s(item.vendorName))]),_c('span',{staticClass:"d-block"},[_vm._v(_vm._s(item.vendorEmail))])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","color":item.status == 'Active'
          ? 'success'
          : item.status == 'Pending Mandate'
            ? 'warning'
            : 'primary'}},[_vm._v(" "+_vm._s(item.status)+" ")])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }